import AEMCube from './visualization/entrypoints/AEMCube.js';
import SCurve from './visualization/entrypoints/SCurve.js';
import Scale from './visualization/entrypoints/Scale.js';
import NummericalValue from './visualization/entrypoints/NumericalValue.js';
import VisualizationType from './visualization/enums/visualizationType.js';
import CameraPreset from './visualization/enums/cameraPreset.js';
import PointerType from './visualization/enums/pointerType.js';

require('../html/visualization.html');

const hasValue = value => typeof value !== "undefined" && value !== null;

const transformBooleanString = (value) => {
    if(value === "true") {
        value = true;
    }

    if(value === "false") {
        value = false;
    }

    return value;
}

const transformEnum = value => {
    if(typeof value === "string" && value !== "") {
        const split = value.split('.');

        if(typeof split[1] !== "undefined" && split[1] !== null) {

            switch(split[0]) {
                case "PointerType":
                    value = PointerType[split[1]];
                    break;
                case "CameraPreset":
                    value = CameraPreset[split[1]];
                    break;
            }

        }
    }

    return value;
};

const transformHexColorString = value => {
    if(typeof value === "string" && value.startsWith("0x")) {
        value = parseInt(value);
    }

    return value;
}

const transformValues = data => {
    for (var key in data)
    {
        if (typeof data[key] == "object" && data[key] !== null) {
            transformValues(data[key]);
        } else {
            if(hasValue(data[key])) {
                data[key] = transformBooleanString(data[key]);
                data[key] = transformEnum(data[key]);
                data[key] = transformHexColorString(data[key]);
            }
        }
    }

    return data;
};

window.executeLogic = function (data) {
    data = transformValues(data);

    const canvasContainerElement = document.getElementById('canvasContainer');
    const styles = [];

    if(data.visualization.width !== null) {
        styles.push(`width: ${data.visualization.width}px;`);
    }

    if(data.visualization.height !== null) {
        styles.push(`height: ${data.visualization.height}px;`);
    }

    canvasContainerElement.setAttribute('style', styles.join(" "));
    debugger;
    if(canvasContainerElement !== null) {

        switch(data.visualization.type) {
            case VisualizationType.AEMCube:
                    window.AEMCube = new AEMCube(data);
                break;
            case VisualizationType.SCurve:
                    window.SCurve = new SCurve(data);
                break;
            case VisualizationType.NumericalValue:
                    window.NummericalValue = new NummericalValue(data);;
                break;
                case VisualizationType.Scale:
                    window.Scale = new Scale(data);
                break;
        }
        
    }

    return true;
}